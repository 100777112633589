@import url('https://fonts.googleapis.com/css?family=Sofia');
@import url('https://fonts.googleapis.com/css2?family=Glegoo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glegoo:wght@400;700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

body {
    font-family: "Roboto Mono", monospace;
    font-style: normal;
    font-size: 24px;
}

.content-background {
    background-color: rgb(170, 116, 51);
    min-height: 100vh;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(5px + 2vmin);
    color: white;
    padding: 0 0;
    margin: 0 0;
}

.header-general {
    background-color: #212226;
    width: 100%;
    height: calc(30px + 2vmin);
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: end;
    color: white;
}

.header-title-general {
    margin-right: 16px;
    padding: 8px 16px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    font-family: "Sofia", sans-serif;
    text-shadow: 2px 2px 7px #ababab;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    height: 100px; /* Set your desired header height here */
    background-color: lightblue;
}

.content {
    flex: 1;
    background-color: lightgreen;
    overflow-y: auto; /* Add scrollbar if content exceeds height */
}

