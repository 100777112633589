.view-editor {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #212226;
    padding: 5px;
    height: 100%;
}

strong {
    background: linear-gradient(90.09deg, #cc30b9 20.39%, #57c1ce 49.26%, #66ddc6 78.13%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
}

.content-box {
    background-color: #4c575e;
    height: 100%;
    width: 100%;
    padding: 10px 0;
    border-radius: 8px;
}

.content-devider {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    width: 100%;
}

.content-side {
    height: 100%;
    background-color: #4c575e;
    min-width: calc(50% - 10px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
}

.edit-side {
    background-color: #3d4d57;
    min-width: calc(50% - 10px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
}